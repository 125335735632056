/**
 *
 */
import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row, Select} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import {PAGE_SIZE, SUCCESS} from '../../utils/constants'
import {addAlarmResponse, updateAlarmResponse, deleteAlarmResponse, queryAlarmResponse, queryDeviceArea} from '../../api/safoneIndex'

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
export default class  extends Component {
    state={
        pageState:'table_list',
        imgList:[],
        tableData:[],
        loading:true,
        addModalOpen: false,
        updateModalOpen: false,
        updateOne: {},
        deviceAreaMap: {},
        deviceAreaList: [],
    }
    searchForm = React.createRef();
    addForm = React.createRef();
    updateForm = React.createRef();

    componentWillMount(){
        this.initColumn()
        this.reqGetAllDeviceArea()
    }
    componentWillUnmount(){
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {deviceAreaList,pageState,tableData,loading, addModalOpen, updateModalOpen, updateOne} = this.state
        const  contentList={
            card_title:<PageTitle title="责任人"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="name">
                                <Input style={{width: 150}} placeholder="姓名"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="phoneNumber">
                                <Input style={{width: 150}} placeholder="手机号"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.setState({addModalOpen: true})}}>添加</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            add_modal: <Modal
                forceRender={true}
                title="添加责任人"
                visible={addModalOpen}
                confirmLoading={loading}
                destroyOnClose={true}
                width={500}
                onOk={async()=>{
                    this.reqAddAlarmResponse()
                }}
                onCancel={()=>{this.setState({addModalOpen:false,})}}>
                <Form ref={this.addForm} style={{width:'100%'}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="姓名" name="name"  rules={[{required: true, message: '请输入姓名！'}]}>
                                <Input  placeholder="请输入姓名"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="电话号码" name="phoneNumber"  rules={[{required: true, message: '请输入电话号码！'}]}>
                                <Input  placeholder="请输入电话号码"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="备注" name="desc">
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="责任区域" name="responseAreaList"  rules={[{required: true, message: '请选择责任区域！'}]}>
                                <Select
                                    options={deviceAreaList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            update_modal: <Modal
                forceRender={true}
                title="修改责任人"
                visible={updateModalOpen}
                confirmLoading={loading}
                width={500}
                destroyOnClose={true}
                onOk={async()=>{
                    this.reqUpdateAlarmResponse()
                }}
                onCancel={()=>{this.setState({updateModalOpen:false,})}}>
                <Form ref={this.updateForm} style={{width:'100%'}} initialValues={{...updateOne}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="姓名" name="name"  rules={[{required: true, message: '请输入姓名！'}]}>
                                <Input  placeholder="请输入姓名"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} style={{marginBottom:10}} label="电话号码" name="phoneNumber"  rules={[{required: true, message: '请输入电话号码！'}]}>
                                <Input  placeholder="请输入电话号码"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} style={{marginBottom:10}} label="备注" name="desc">
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} style={{marginBottom:10}} label="责任区域" name="responseAreaList"  rules={[{required: true, message: '请选择责任区域！'}]}>
                                <Select
                                    options={deviceAreaList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            table_list:<MyTable column={this.columns} data={tableData} loading={loading} pageFun={(c)=>{this.getList(c)}}/>
        }
        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
                {contentList['add_modal']}
                {contentList['update_modal']}
            </div>
        )
    }

    reqGetAllDeviceArea = async ()=>{
        const res = await queryDeviceArea({pageSize:999})
        if(res?.code === SUCCESS){
            const map = {}
            if(res?.results){
                res.results.forEach((da)=>{
                    map[da.id] = da.name
                })
            }
            this.setState({deviceAreaList: res.results || [], deviceAreaMap: map})
        }else{
            message.error(`获取设备区域失败:${res?.message ||'-'}`)
        }
    }

    reqAddAlarmResponse = async ()=>{
        this.setState({loading: true})
        const _that = this
        this.addForm.current.validateFields().then(async (values)=>{
            const res = await addAlarmResponse({...values});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({addModalOpen: false})
                _that.getList();
            }else{
                message.error(`添加失败:${res?.message ||'-'}`)
            }
        })
        this.setState({loading: false})
    }
    reqUpdateAlarmResponse = async ()=>{
        this.setState({loading: true})
        const {updateOne} = this.state;
        const _that = this
        this.updateForm.current.validateFields().then(async (values)=>{
            const res = await updateAlarmResponse({...values, id: updateOne.id});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({updateModalOpen: false, updateOne: {}})
                _that.getList();
            }else{
                message.error(`修改失败:${res?.message ||'-'}`)
            }
        });
        this.setState({loading: false})
    }

    showDeleteConfirm = (record) => {
        const {confirm} = Modal
        confirm({
            title:'删除责任人',
            content: `确认删除责任人：${record.name} 吗`,
            onOk:async ()=>{
                const res = await deleteAlarmResponse({id: record.id})
                if(res?.code === SUCCESS){
                    Modal.destroyAll();
                    this.getList();
                }else{
                    message.error(`修改失败:${res?.message ||'-'}`)
                }
            }
        })
    }

    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        value.pageNum = page || 1
        value.pageSize = PAGE_SIZE
        let res = await queryAlarmResponse(value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum=page
            this.setState({tableData:res, pageNum:page})
        }
    }
    initColumn = ()=>{
        this.columns=[
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '手机号',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: '备注',
                dataIndex: 'desc',
                key: 'desc',
            },
            {
                title: '责任区域',
                dataIndex: 'responseAreaList',
                key: 'responseAreaList',
                render: (_, record)=>{
                    const {deviceAreaMap} = this.state
                    return record?.responseAreaList.map(id => deviceAreaMap[id]).join(',') || ''
                }
            },
            {
                title: '操作',
                dataIndex: '-',
                key: 'id',
                render: (_, record)=>{
                    return (<div>
                        <Button type={'link'} onClick={()=>this.showDeleteConfirm(record)}>删除</Button>
                        <Button type={'link'} onClick={()=>{this.setState({updateOne: record}, ()=>this.setState({updateModalOpen:true}))}}>修改</Button>
                    </div>)
                }
            },
        ]
    }

}