import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row, Select, Table, Upload} from 'antd'
import {reqAddDevice, reqGetDeviceList, reqGetDeviceListUnbind, reqUpdateDevice,} from "../../api/index"
import {SUCCESS} from "../../utils/constants";
import {ExclamationCircleOutlined, UploadOutlined} from '@ant-design/icons';
import sessionUtils from "../../utils/sessionUtils";
import Map from "./map/deviceMap";
import SetPositionMap from "./map/setPositionDeviceMap";
import {URL_SAFONE} from "../../config/urlConf/urlConf";
import {parseXhrResponse} from "../../utils/aladdin";
import moment from "moment";
import {queryDeviceArea} from "../../api/safoneIndex";

const PAGE_SIZE = 10;
const extra_map = {
    "table_info": "card_main",
}
const header_map = {
    "table_info": "安防设备管理",
    "update_page": "修改设备",
    "add_page": "新增设备"
}

const {confirm} = Modal;
let deviceListTimer = null;
export default class Device extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageStatus: "table_info",
            dataList: [],
            dataTotal: 0,
            pageNum: 1,
            buttonLoading: false,
            addType: 1,
            tableLoading: false,
            cameraOptions: [],
            cameraList: [],
            filePath: '',
            updateOne: {},
            loadingBtn: "",
            deviceGroupList: [],
            deviceTagList: [],
            tableParam: {},
            deviceMapVisible: false,
            deviceSetPointMapVisible: false,
            selectSetMarkDevice: {},
            upLoadStatus: false,
            btnId: "",
            sentPercentMap: {},
            otaPercentMap: {},
            btnLoadingMap: {},
            deviceAreaMap: {},
            deviceAreaList: [],
        }
    }

    searchFormRef = React.createRef();
    addFormRef = React.createRef();
    updateFormRef = React.createRef();
    recordFormRef = React.createRef();
    deviceMap = React.createRef();
    deviceSetPointMap = React.createRef();

    componentWillMount() {
        if (!deviceListTimer) {
            deviceListTimer = setInterval(() => {
                this.getDeviceList()
            }, 30000);
        }
        this.initColumns();
    }

    componentWillUnmount() {
        if (deviceListTimer) {
            clearInterval(deviceListTimer);
        }
    }

    componentDidMount() {
        this.getDeviceList({pageNum: 1});
        this.reqGetAllDeviceArea();
    }

    render() {
        const {
            deviceAreaList,deviceAreaMap,selectSetMarkDevice, deviceSetPointMapVisible, deviceMapVisible, cameraOptions,
            pageStatus, loading, dataList, dataTotal, pageNum, buttonLoading
        } = this.state;

        const _that = this;
        const formItemLayout = {
            labelCol: {span: 4},
            wrapperCol: {span: 6},
        };
        const contentList = {
            table_info: <Table columns={this.column}
                               bordered
                               size="small"
                               rowKey={record => record.id}
                               dataSource={dataList}
                               loading={loading}
                               scroll={{x: 1500}}
                               rowClassName={(record, index) => {
                                   let className = 'light-row';
                                   if (index % 2 === 1) className = 'dark-row';
                                   return className;
                               }}
                               pagination={{
                                   total: dataTotal,
                                   showTotal: function () {  //设置显示一共几条数据
                                       return '共 ' + dataTotal + ' 条数据';
                                   },
                                   current: pageNum,
                                   pageSize: PAGE_SIZE,
                                   onChange(current) {
                                       const form = _that.searchFormRef.current;
                                       let param = form.getFieldsValue();
                                       param.pageNum = current;
                                       _that.getDeviceList(param)
                                   },
                               }}
            />,
            card_main: <div style={{margin: "10px 0", position: "relative"}}>
                <Form ref={this.searchFormRef}
                      style={{margin: "0 15px ", display: "inline-block"}}
                      name="advanced_search"
                      className="ant-advanced-search-form"
                      onFinish={this.searchOnFinish}>
                    <Row gutter={24}>
                        <Col>
                            <Button type="primary" onClick={() => {
                                this.setState({pageStatus: "add_page"})
                                this.getAllCamera();
                            }}>新增</Button>
                        </Col>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="deviceId">
                                <Input style={{width: 150}} placeholder="设备序列号"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={() => {
                                this.searchFormRef.current.resetFields()
                            }}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            record_extra: <div style={{margin: "10px 0", position: "relative"}}>
                <Form ref={this.recordFormRef}
                      style={{margin: "0 15px ", display: "inline-block"}}
                      name="advanced_search"
                      className="ant-advanced-search-form"
                >
                    <Row gutter={24}>
                        <Col>
                            <Button onClick={() => {
                                this.setState({pageStatus: "table_info", loadingBtn: "",})
                            }} style={{marginLeft: "10px"}}>返回</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            update_page: <div>
                <Form ref={this.updateFormRef} onFinish={this.updateDevice}>
                    <Form.Item label="设备序号" name={'deviceId'} {...formItemLayout}
                    >
                        <Input type='text' disabled={true}/>
                    </Form.Item>
                    <Form.Item label='绑定摄像头'
                               name='cameraDeviceId'
                               {...formItemLayout}
                               rules={[{required: true, message: "请选择摄像头"}]}
                    >
                        <Select
                            showSearch
                            style={{width: 200}}
                            options={cameraOptions}
                            onChange={(key) => {
                                this.onCameraChange(key)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='报警电话号码'
                               name='alarmPhoneNumber'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='设备位置'
                               name='coordinate'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='备注'
                               name='remark'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="所属区域" name="deviceAreaId"  rules={[{required: true, message: '请选择所属区域！'}]}>
                        <Select
                            options={deviceAreaList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                        />
                    </Form.Item>
                    <div style={{width: "60%", textAlign: "right"}}>
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>保存</Button>
                        <Button style={{margin: '0 8px'}} onClick={() => {
                            this.setState({pageStatus: "table_info"})
                        }}>取消</Button>
                    </div>
                </Form>
            </div>,
            add_page: <div>
                <Form ref={this.addFormRef} onFinish={this.addDevice}>
                    <Form.Item label="设备序号" name={'deviceId'} {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='绑定摄像头'
                               name='cameraDeviceId'
                               {...formItemLayout}
                               rules={[{required: true, message: "请选择摄像头"}]}
                    >
                        <Select
                            showSearch
                            style={{width: 200}}
                            options={cameraOptions}
                            onChange={(key) => {
                                this.onCameraChange(key)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='报警电话号码'
                               name='alarmPhoneNumber'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='设备位置'
                               name='coordinate'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item label='备注'
                               name='remark'
                               {...formItemLayout}
                    >
                        <Input type='text'/>
                    </Form.Item>
                    <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="所属区域" name="deviceAreaId"  rules={[{required: true, message: '请选择所属区域！'}]}>
                        <Select
                            options={deviceAreaList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                        />
                    </Form.Item>
                    <div style={{width: "60%", textAlign: "right"}}>
                        <Button type="primary" htmlType="submit" loading={buttonLoading}>保存</Button>
                        <Button style={{margin: '0 8px'}} onClick={() => {
                            this.setState({pageStatus: "table_info"})
                        }}>取消</Button>
                    </div>
                </Form>
            </div>,
        }
        return (<div className={"page_home"}>
            <Card
                className={"card_main"}
                title={<span style={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}>{header_map[pageStatus]}</span>}
                extra={contentList[extra_map[pageStatus]]}
            >
                <div style={{height: '100%', display: 'flex'}}>
                    <div style={{height: '100%', width: '95%', margin: 10}}>
                        {contentList[pageStatus]}
                    </div>
                </div>
            </Card>
            <Modal
                forceRender={true}
                title="地图位置"
                width="600px"
                okText='确定'
                cancelText='关闭'
                visible={deviceMapVisible}
                onOk={() => {
                    this.setState({deviceMapVisible: false})
                }}
                onCancel={() => {
                    this.setState({deviceMapVisible: false})
                }}>
                <Map ref={this.deviceMap}/>
            </Modal>
            <Modal
                forceRender={true}
                title="修改地图位置"
                width="800px"
                okText='确定'
                cancelText='关闭'
                visible={deviceSetPointMapVisible}
                onOk={async () => {
                    // this.setState({deviceSetPointMapVisible:false})
                    const {selectSetMarkDevice} = this.state;
                    if (selectSetMarkDevice.pos || selectSetMarkDevice.locationDescIsChange) {
                        this.showConfirm(selectSetMarkDevice)
                    } else {
                        this.setState({deviceSetPointMapVisible: false, selectSetMarkDevice: {}})
                    }
                }}
                onCancel={() => {
                    this.setState({deviceSetPointMapVisible: false, selectSetMarkDevice: {}})
                }}>
                <SetPositionMap parent={this} ref={this.deviceSetPointMap}/>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        位置描述：
                    </div>
                    <div style={{width: '80%'}}>
                        <Input onChange={(e) => {
                            let selectSetMarkDevice = this.state.selectSetMarkDevice;
                            selectSetMarkDevice.locationDesc = e.target.value;
                            if (!selectSetMarkDevice.pos) {
                                selectSetMarkDevice.pos = ''
                            }
                            selectSetMarkDevice.locationDescIsChange = true
                            this.setState({selectSetMarkDevice: selectSetMarkDevice})

                        }}
                               value={selectSetMarkDevice.locationDesc ? selectSetMarkDevice.locationDesc : null}
                               placeholder={'请输入位置描述信息'}/>
                    </div>
                </div>
            </Modal>
        </div>);
    }

    initColumns = () => {
        const uploadAudioProps = {
            name: 'file',
            action: URL_SAFONE + '/api/safe/device/updateAudio',
            headers: {
                authorization: sessionUtils.getUser().token,
            },
            accept: '.mp3,.pcm16,.pcm8,.wav',
            showUploadList: false,
            onChange: (info) => {
                this.setState({
                    upLoadStatus: true
                }, () => {
                    if (info.file.status === 'uploading') {
                        this.setState({
                            upLoadStatus: true
                        })
                    } else if (info.file.status === 'done') {
                        const response = parseXhrResponse(info.file.xhr);
                        if (response.code === SUCCESS) {
                            this.uploadTime = setTimeout(() => {
                                this.setState({
                                    upLoadStatus: false
                                }, () => {
                                    message.success("上传成功");
                                    clearTimeout(this.uploadTime)
                                })
                            }, 1000);

                        } else {
                            message.error("上传失败: " + response.message);
                        }
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} 上传失败！`);
                        this.setState({
                            upLoadStatus: false
                        })
                    }
                })
            },
        };
        this.column = [
            {
                title: "设备号",
                dataIndex: 'deviceId',
                key: 'deviceId',
                width: 120,
            },
            {
                title: "报警电话",
                dataIndex: 'alarmPhoneNumber',
                key: 'alarmPhoneNumber',
                width: 80,
            },
            {
                title: "摄像头编号",
                dataIndex: 'cameraDeviceId',
                key: 'cameraDeviceId',
                width: 120,
            },
            {
                title: "备注",
                dataIndex: 'remark',
                key: 'remark',
                width: 100,
            },
            {
                title: "4g信号",
                dataIndex: 'signal',
                key: 'signal',
                width: 100,
            },
            // {
            //     title: "最后上报时间",
            //     dataIndex: 'reportTime',
            //     key: 'reportTime',
            //     width: 100,
            // },
            {
                title: "状态",
                dataIndex: 'online',
                key: 'online',
                width: 50,
                render: (e, record, idx) => {
                    if(record.reportTime){
                        const reportTime = moment(record.reportTime);
                        if(moment().subtract(10, "minutes").isBefore(reportTime)){
                            return <span style={{color:"green"}}>在线</span>;
                        }else{
                            return <span style={{color:"red"}}>待机</span>;
                        }
                    }
                    return <span style={{color:"red"}}>待机</span>;
                }
            },
            {
                title: '地图位置',
                key: 'pos',
                align: 'center',
                width: 100,
                render: (e) => {
                    return (
                        <div>
                            <Button type="link" onClick={() => {
                                this.setState({MapPos: e.coordinate}, () => {
                                    this.deviceMap.current.initMarker(e.coordinate);
                                    this.setState({deviceMapVisible: true})
                                })
                            }}>地图位置</Button>
                            <Button type="link" onClick={() => {
                                this.setState({selectSetMarkDevice: {id: e.id}}, () => {
                                    this.deviceSetPointMap.current.initMarker(e.coordinate);
                                    this.state.selectSetMarkDevice.locationDesc = e.locationDesc
                                    this.setState({deviceSetPointMapVisible: true})
                                });
                            }}>更新</Button>
                        </div>
                    )
                }
            },
            {
                title: "报警语音",
                dataIndex: 'alarmPhoneNumber',
                key: 'alarmPhoneNumber',
                width: 80,
                render: (text, record, idx)=>{
                    return (<div>
                        <Upload {...uploadAudioProps} data={{id: record.id}}>
                            <Button
                                icon={<UploadOutlined/>}
                                loading={this.state.btnId === record.id ? this.state.upLoadStatus : false}
                                onClick={() => {
                                    this.setState({btnId: record.id})
                                }}>修改</Button>
                        </Upload>
                    </div>);
                }
            },
            {
                title: "一键喊话",
                dataIndex: 'alarmPhoneNumber',
                key: 'alarmPhoneNumber',
                width: 80,
                render: (text, record, idx)=>{
                    return (<div>
                        <Upload {...this.getSendProps(record)} data={{deviceId: record.deviceId}}>
                            <Button type={'primary'} loading={this.state.sentPercentMap[record.id] && this.state.sentPercentMap[record.id]!==100}>上传</Button>
                        </Upload>
                    </div>);
                }
            },
            {
                title: "在线升级",
                dataIndex: 'alarmPhoneNumber',
                key: 'alarmPhoneNumber',
                width: 80,
                render: (text, record, idx)=>{
                    return (<div>
                        <Upload {...this.getOTAProps(record)} data={{deviceId: record.deviceId}}>
                            <Button type={'primary'} loading={this.state.otaPercentMap[record.id] && this.state.otaPercentMap[record.id]!==100}>上传</Button>
                        </Upload>
                    </div>);
                }
            },
            {
                title: "操作",
                dataIndex: '',
                key: 'delete',
                fixed: "right",
                width: 120,
                render: (e, record) => {
                    return <div>
                        <Button type="link" onClick={() => {
                            this.showUpdateDevice(record)
                            this.getAllCamera(record.id);
                        }}>修改</Button>
                        <Button type='link' onClick={()=>{this.goVideo(record.cameraDto)}}>实时视频</Button>
                        <Button type='link' onClick={()=>{this.goGBRecord(record.cameraDto)}}>录像</Button>
                    </div>
                }
            },
        ]
    }

    getSendProps=(device)=>{
        return {
            name: 'file',
            action: URL_SAFONE + '/api/device/j20/sendRealtimeAudioFile',
            headers: {
                authorization: sessionUtils.getUser().token,
            },
            accept:'.mp3,.pcm16,.pcm8,.wav',
            showUploadList: false,
            onChange: (info) => {
                const {sentPercentMap} = this.state;
                sentPercentMap[device.id] = true;
                if (info.file.status === 'uploading') {
                    this.setState({
                        sentPercentMap: sentPercentMap
                    })
                }else if (info.file.status === 'done') {
                    const response = parseXhrResponse(info.file.xhr);
                    if (response.code === SUCCESS) {
                        message.success("上传成功");
                    } else {
                        message.error("上传失败: " + response.message);
                    }
                    sentPercentMap[device.id] = false;
                    this.setState({
                        sentPercentMap: sentPercentMap
                    })
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败！`);
                    sentPercentMap[device.id] = false;
                    this.setState({
                        sentPercentMap: sentPercentMap
                    })
                }
            },
        }
    }

    getOTAProps=(device)=>{
        return {
            name: 'file',
            action: URL_SAFONE + '/api/device/j20/sendOTAFile',
            headers: {
                authorization: sessionUtils.getUser().token,
            },
            showUploadList: false,
            onChange: (info) => {
                const {otaPercentMap} = this.state;
                otaPercentMap[device.id] = true;
                if (info.file.status === 'uploading') {
                    this.setState({
                        otaPercentMap: otaPercentMap
                    })
                }else if (info.file.status === 'done') {
                    const response = parseXhrResponse(info.file.xhr);
                    if (response.code === SUCCESS) {
                        message.success("上传成功");
                    } else {
                        message.error("上传失败: " + response.message);
                    }
                    otaPercentMap[device.id] = false;
                    this.setState({
                        otaPercentMap: otaPercentMap
                    })
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败！`);
                    otaPercentMap[device.id] = false;
                    this.setState({
                        otaPercentMap: otaPercentMap
                    })
                }
            },
        }
    }

    goVideo=(deviceInfo)=>{
        if(deviceInfo){
            sessionUtils.saveDeviceInfo(deviceInfo);
            window.location.href='./#/video'
        }else{
            message.error("尚未绑定摄像头");
        }
    }

    goGBRecord=async (deviceInfo)=>{
        if(deviceInfo){
            sessionUtils.saveDeviceInfo(deviceInfo);
            window.location.href='./#/record'
        }else{
            message.error("尚未绑定摄像头");
        }
    }

    getDeviceList = async (param) => {
        param = param ? param : this.state.tableParam;
        param.pageNum = param.pageNum ? param.pageNum : 1;
        this.setState({tableLoading: true, tableParam: param});
        const res = await reqGetDeviceList(param);
        this.setState({tableLoading: false});
        if (res.code === SUCCESS) {
            this.setState({
                dataList: res.results,
                dataTotal: res.totalCount,
                pageNum: param.pageNum
            })
        } else {
            message.error("获取分组列表失败");
        }
    }

    getAllCamera = async (id) => {
        const res = await reqGetDeviceListUnbind(id ? {safeDeviceId: id} : null);
        if (res.code === SUCCESS) {
            this.setState({
                cameraOptions: res.results ? res.results.map((camera) => {
                    return {label: camera.deviceId, value: camera.deviceId}
                }) : [],
                cameraList: res.results
            })
        }
    }

    addDevice = async (values) => {
        this.setState({buttonLoading: true});
        console.log(values)
        const res = await reqAddDevice(values);
        this.setState({buttonLoading: false});
        if (res.code === SUCCESS) {
            message.success("添加成功")
            this.setState({
                pageStatus: 'table_info',
            }, () => {
                this.getDeviceList({pageNum: 1});
            })
        } else {
            message.error("新增失败");
        }
    }

    searchOnFinish = (values) => {
        values.pageNum = 1;
        this.getDeviceList(values);
    }

    showUpdateDevice = (updateOne) => {
        const _that = this;
        this.setState({
            updateOne: updateOne,
            pageStatus: 'update_page',
            cameraId: updateOne.cameraId,
        }, () => {
            const form = _that.updateFormRef.current;
            if (!updateOne.deviceTagIds) {
                updateOne.deviceTagIds = [];
            }
            if (form) {
                form.setFieldsValue(updateOne);
            }
        })
    }

    updateDevice = async (values) => {
        const {updateOne} = this.state;
        values.id = updateOne.id;
        this.setState({buttonLoading: true});
        const res = await reqUpdateDevice(values);
        this.setState({buttonLoading: false});
        if (res.code === SUCCESS) {
            message.success("修改成功")
            this.setState({
                pageStatus: 'table_info',
            }, () => {
                this.getDeviceList({pageNum: 1});
            })
        } else {
            message.error("修改失败");
        }
    }

    reqGetAllDeviceArea = async ()=>{
        const res = await queryDeviceArea({pageSize:999})
        if(res?.code === SUCCESS){
            const map = {}
            if(res?.results){
                res.results.forEach((da)=>{
                    map[da.id] = da.name
                })
            }
            this.setState({deviceAreaList: res.results || [], deviceAreaMap: map})
        }else{
            message.error(`获取设备区域失败:${res?.message ||'-'}`)
        }
    }

    dto2treeData(treeData) {
        let nodes = [];
        if (treeData) {
            treeData.map(e => {
                let node = {
                    "key": e.id,
                    "title": e.name,
                    "value": e.id,
                    "fullName": e.fullName
                };
                if (e.children) {
                    node.children = this.dto2treeData(e.children);
                }
                nodes.push(node);
            })
        }
        return nodes;
    }

    onCameraChange = (cameraId) => {
        const {cameraList} = this.state;
        if (cameraList) {
            const camera = cameraList.find(camera => camera.id === cameraId);
            if (camera) {
                // this.setState({localType:local.type})
                const cameraId = camera.cameraId;
                const form = this.updateFormRef.current;
                if (form) {
                    let values = form.getFieldsValue();
                    values.cameraId = cameraId;
                    form.setFieldsValue(JSON.parse(JSON.stringify(values)));
                }
                const form2 = this.addFormRef.current;
                if (form2) {
                    let values = form2.getFieldsValue();
                    values.cameraId = cameraId;
                    form2.setFieldsValue(JSON.parse(JSON.stringify(values)));
                }
            }
        }
    }


    setSelectSetMark = (pos) => {
        const {selectSetMarkDevice} = this.state;
        let posObject = {
            pos: pos
        }
        this.setState({selectSetMarkDevice: {...selectSetMarkDevice, ...posObject}})
    };
    showConfirm = (value) => {
        const _that = this;
        confirm({
            title: '确定要修改设备在地图位置吗？',
            icon: <ExclamationCircleOutlined/>,
            okText: '确定',
            // okType: 'danger',
            cancelText: '取消',
            onOk() {
                _that.updateLngLat(value).then(r => r);
                _that.setState({deviceSetPointMapVisible: false, selectSetMarkDevice: {}})
            },
            onCancel() {

            },
        });
    }
    updateLngLat = async (value) => {
        value.coordinate = value.pos
        const res = await reqUpdateDevice(value);
        if (res.code === 200) {
            message.success("更改成功！")
            await this.getDeviceList()
        } else {
            message.info('更改失败！')
        }
    };
}

