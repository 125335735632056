import axios from 'axios'
import sessionUtil from '../utils/sessionUtils'
import {message} from 'antd'

/**
 * 服务器状态码
 * @type {{200: string, 201: string, 202: string, 204: string, 400: string, 401: string, 403: string, 404: string, 406: string, 410: string, 422: string, 500: string, 502: string, 503: string, 504: string}}
 */
const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};


// 创建拦截器
let axiosInstance = axios.create();

let getToken = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    return user?user.token:null;
}

// axiosSetting.js
// request拦截器
axiosInstance.interceptors.request.use(
    config => {
        const token = getToken();
        if(config.url.indexOf('/login') > 0){
        }else{
            config.headers.Authorization = token
        }
        return config
    },
    error => {
        // message.error(err.message);
        message.error("网络错误");
        Promise.reject(error)
    }
)


const HttpClientget = (url,params,headers) =>{
    return new Promise((resolve, reject) => {
        axiosInstance.get(url,{params:params},{headers:headers}
        ).then(res => {
            checkRes(res.data)
            resolve(res.data)
        }).catch(err => {
            reject(err)
            // message.error(err.message);
            message.error("网络错误");
        })
    })
}

const HttpClientpost = (url,params,headers) =>{
    return new Promise((resolve, reject) => {
        axiosInstance.post(url,params,{headers:headers}
        ).then(res => {
            checkRes(res.data)
            resolve(res.data)
        }).catch(err => {
            resolve({
                code:500,
                message:err.message
            })
            // message.error(err.message);
            message.error("网络错误");
        })
    })
}

const HttpClientput = (url,params,headers) =>{
    return new Promise((resolve, reject) => {
        axiosInstance.put(url,params,{headers:headers}
        ).then(res => {
            checkRes(res.data)
            resolve(res.data)
        }).catch(err => {
            reject(err)
            // message.error(err.message);
            message.error("网络错误");
        })
    })
}

const HttpClientdelete = (url,params,headers) =>{
    return new Promise((resolve, reject) => {
        axiosInstance.delete(url,params,{headers:headers}
        ).then(res => {
            checkRes(res.data)
            resolve(res.data)
        }).catch(err => {
            reject(err)
            // message.error(err.message);
            message.error("网络错误");
        })
    })
}


const FetchApi = (url, data) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        }).then((stories) => {
            resolve(stories);
        }).catch((err) => {
            reject(err);
            // message.error(err.message);
            message.error("网络错误");
        })
    });
}

/**
 * 若状态码为200返回true
 * 若不为200返回false
 * @param res
 */
const checkRes=(res)=>{
    if (!(res.code === 200)) {
        if(res.code === 401){
            message.info({content:'该账户在其他地方登录，请重新登录',duration:'4',key:'tokenError'})
            sessionUtil.deleteUser()
            setTimeout(()=>{
                window.location.href='/'
            },3000)
            return false
        }
        if(res.message){
            // message.error('错误代码'+ res.code+","+ res.message);
            message.error({content:res.message,key:'netError'});
            return false;
        }else {
            for(let key in codeMessage){
                if(res.code === key)
                    // message.error('错误代码'+res.code+","+codeMessage[key]);
                    message.error({content:codeMessage[key],key:'netError'});
                return false;
            }

        }
        // message.error('错误代码'+res.code+","+'未知错误')
        message.error({content:'未知错误',key:'netError'})
        return false;
    }
    return true;
}


export  {HttpClientget,HttpClientpost,HttpClientput,HttpClientdelete,FetchApi}
