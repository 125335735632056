/**
 *
 */
import React, {Component} from 'react'
import './admin.less'
import {HeaderBar} from '../../components/HeaderBar/header'
import {ConfigProvider, Layout} from 'antd';
import {Redirect, Route, Switch} from 'react-router-dom'
import sessionUtil from '../../utils/sessionUtils'
import SiderNav from '../../components/SiderNav'
import '../../common/skinTollBox/index.less'
import menuList from '../../config/menusConf/menu'
import zhCN from 'antd/es/locale/zh_CN';
/**
 * 路由页面
 */
import NotFound from '../../common/ErrorPage';
import AlarmMessage from '../../components/pageAlarm/alarmMessage';
import AlarmManage from '../../components/pageAlarm/alarmManage';
import AlarmNote from '../../components/pageAlarm/alarmNote';
import College from '../../components/pageData/college';
import DeviceMap from '../../components/pageFunction/deviceMap';
import DeviceLog from '../../components/pageFunction/deviceLog';
import Device from '../../components/pageFunction/device';
import Camera from '../../components/pageFunction/camera';
import SafeDevice from '../../components/pageFunction/safeDevice';
import Monitoring from '../../components/pageFunction/monitoring';
import Version from '../../components/pageFunction/version';
import Reservoir from '../../components/pageFunction/reservoir';
import Face from '../../components/pagePerson/face';
import Principal from '../../components/pagePerson/principal';
import Organization from '../../components/pageFunction/organization';
import User from '../../components/pageBackend/user';
import Role from '../../components/pageBackend/role';
import DeviceArea from '../../components/pageNew/deviceArea';
import AlarmResponse from '../../components/pageNew/alarmResponse';
import FaceManage from '../../components/pageNew/faceManage';
import FaceAlarm from '../../components/pageNew/faceAlarm';


const { Sider, Content } = Layout;
export default class Admin extends Component {
    state={
        divHeight:0
    }
    div = React.createRef();
    componentDidMount(){
        this.getdiv()
    }
    render() {
            const user = sessionUtil.getUser();
            if(!user||!user.token){
                return <Redirect to="/login"/>
            }
        return (
            <ConfigProvider locale={zhCN} >
                <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
                    <HeaderBar/>
                    <Layout>
                        <Sider  theme={'light'}  breakpoint="lg" collapsedWidth="0" onBreakpoint={broken => {console.log(broken);}} onCollapse={(collapsed, type) => {console.log(collapsed, type);}}>
                            <div className="logo"/>
                            <SiderNav  theme={'light'}  menuList={this.setMeuListWithPermission(menuList).menuList}/>
                        </Sider>
                        <Content style={{width:'100%',flexGrow:this.state.divHeight}}>
                            <Switch>
                                <Redirect exact={true} from='/' to={this.setMeuListWithPermission(menuList).urlList[1]||'/'}/>
                                {user.permissionList.includes("warning-info")?<Route path="/alarm/alarmMessage" component={AlarmMessage} />:null}
                                {user.permissionList.includes("warning-info")?<Route path="/alarm/faceAlarm" component={FaceAlarm} />:null}
                                {user.permissionList.includes("warning-manage")? <Route path="/alarm/alarmManage" component={AlarmManage} />:null}
                                {user.permissionList.includes("warning-manage")? <Route path="/alarm/alarmNote" component={AlarmNote} />:null}

                                {user.permissionList.includes("people-face")?<Route path="/person/face" component={Face} />:null}
                                {user.permissionList.includes("people-face")?<Route path="/person/faceManage" component={FaceManage} />:null}
                                {user.permissionList.includes("people-inCharge")?<Route path="/person/principal" component={Principal} />:null}
                                {user.permissionList.includes("people-inCharge")?<Route path="/person/response" component={AlarmResponse} />:null}

                                {user.permissionList.includes("function-deviceMap")?<Route path="/function/deviceMap" component={DeviceMap} />:null}
                                {user.permissionList.includes("function-safeDevice")?<Route path="/function/safeDevice" component={SafeDevice} />:null}
                                {user.permissionList.includes("function-monitoring")?<Route path="/function/monitoring" component={Monitoring} />:null}
                                {user.permissionList.includes("function-device")?<Route path="/function/device" component={Device} />:null}
                                {user.permissionList.includes("function-device")?<Route path="/function/camera" component={Camera} />:null}
                                {user.permissionList.includes("function-version")?<Route path="/function/version" component={Version} />:null}
                                {user.permissionList.includes("reservoirAll-reservoir")?<Route path="/function/reservoir" component={Reservoir} />:null}
                                {user.permissionList.includes("reservoirAll-reservoir")?<Route path="/function/deviceArea" component={DeviceArea} />:null}
                                {user.permissionList.includes("function-deviceLog")?<Route path="/function/deviceLog" component={DeviceLog} />:null}

                                {user.permissionList.includes("data-college")?<Route path="/data/college" component={College} />:null}
                                {user.permissionList.includes("backend-organization")? <Route path="/backend/organization" component={Organization} />:null}
                                {user.permissionList.includes("backend-user")? <Route path="/backend/user" component={User} />:null}
                                {user.permissionList.includes("backend-role")?<Route path="/backend/role" component={Role} />:null}
                                {/*<Route path="/alarm/alarmMessage" component={AlarmMessage} />*/}
                                {/*<Route path="/alarm/alarmManage" component={AlarmManage} />*/}
                                {/*<Route path="/person/face" component={Face} />*/}
                                {/*<Route path="/person/principal" component={Principal} />*/}
                                {/*<Route path="/function/device" component={Device} />*/}
                                {/*<Route path="/data/college" component={College} />*/}
                                {/*<Route path="/backend/organization" component={Organization} />*/}
                                {/*<Route path="/backend/user" component={User} />*/}
                                {/*<Route path="/backend/role" component={Role} />*/}
                                {/*<Route path="/screen" component={Screen} />*/}
                                <Route component={NotFound}/>
                            </Switch>
                        </Content>
                    </Layout>
                </div>
            </ConfigProvider>
        )
    }

    /**
     * 获取父元素高度
     */
    getdiv=()=>{
        if(this.div.current){
            let height = this.div.current.offsetHeight
            this.setState({divHeight:height})
        }
    }


    setMeuListWithPermission=(menuList)=>{
        let data = {}
        let urlList = [];
        let user = sessionUtil.getUser();
        let permissionList = user.permissionList;
        for(let i=0;i<menuList.length;i++){
            let item = menuList[i];
            if(!permissionList.includes(item.permission)){
                menuList.splice(i,1);
                i--;
            }
            else {
                // urlList .push(item.key);
              item.subs = this.setMeuSubListWithPermission(item.subs,urlList)
            }

        }
        data.menuList = menuList
        data.urlList = urlList
        return data
    }

    setMeuSubListWithPermission=(menuList,urlList)=>{
        let user = sessionUtil.getUser();
        let permissionList = user.permissionList;
        for(let i=0;i<menuList.length;i++){
            let item = menuList[i];
            if(!permissionList.includes(item.permission)){
                menuList.splice(i,1);
                i--;
            }else {
                urlList.push(item.key)
            }
        }
        return menuList
    }


}