/**
 *
 */
import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import {PAGE_SIZE, SUCCESS} from '../../utils/constants'
import {addOrganization, updateOrganization, deleteOrganization, queryOrganization} from '../../api/safoneIndex'

export default class  extends Component {
    state={
        pageState:'table_list',
        imgList:[],
        tableData:[],
        loading:true,
        addModalOpen: false,
        updateModalOpen: false,
        updateOne: {},
    }
    searchForm = React.createRef();
    addForm = React.createRef();
    updateForm = React.createRef();

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {pageState,tableData,loading, addModalOpen, updateModalOpen, updateOne} = this.state
        const  contentList={
            card_title:<PageTitle title="组织架构"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="name">
                                <Input style={{width: 150}} placeholder="组织名"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.setState({addModalOpen: true})}}>添加</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            add_modal: <Modal
                forceRender={true}
                title="添加组织架构"
                visible={addModalOpen}
                confirmLoading={loading}
                destroyOnClose={true}
                width={500}
                onOk={async()=>{
                    this.reqAddOrganization()
                }}
                onCancel={()=>{this.setState({addModalOpen:false,})}}>
                <Form ref={this.addForm} style={{width:'100%'}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item  style={{marginBottom:10}} label="区域名称" name="name"  rules={[{required: true, message: '请输入区域名称！'}]}>
                                <Input  placeholder="请输入区域名称"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item  style={{marginBottom:10}} label="备注" name="desc"  >
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            update_modal: <Modal
                forceRender={true}
                title="修改区域名称"
                visible={updateModalOpen}
                confirmLoading={loading}
                width={500}
                destroyOnClose={true}
                onOk={async()=>{
                    this.reqUpdateOrganization()
                }}
                onCancel={()=>{this.setState({updateModalOpen:false,})}}>
                <Form ref={this.updateForm} style={{width:'100%'}} initialValues={{...updateOne}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item  style={{marginBottom:10}} label="区域名称" name="name"  rules={[{required: true, message: '请输入区域名称！'}]}>
                                <Input  placeholder="请输入区域名称"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item  style={{marginBottom:10}} label="备注" name="desc"  >
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            table_list:<MyTable column={this.columns} data={tableData} loading={loading} pageFun={(c)=>{this.getList(c)}}/>
        }
        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
                {contentList['add_modal']}
                {contentList['update_modal']}
            </div>
        )
    }

    reqAddOrganization = async ()=>{
        this.setState({loading: true})
        const _that = this
        this.addForm.current.validateFields().then(async (values)=>{
            const res = await addOrganization({...values});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({addModalOpen: false})
                _that.getList();
            }else{
                message.error(`添加失败:${res?.message ||'-'}`)
            }
        });
    }
    reqUpdateOrganization = async ()=>{
        this.setState({loading: true})
        const {updateOne} = this.state;
        const _that = this
        this.updateForm.current.validateFields().then(async (values)=>{
            const res = await updateOrganization({...values, id: updateOne.id});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({updateModalOpen: false, updateOne: {}})
                _that.getList();
            }else{
                message.error(`修改失败:${res?.message ||'-'}`)
            }
        });
    }

    showDeleteConfirm = (record) => {
        const {confirm} = Modal
        confirm({
            title:'删除组织',
            content: `确认删除组织：${record.name} 吗`,
            onOk:async ()=>{
                const res = await deleteOrganization({id: record.id})
                if(res?.code === SUCCESS){
                    Modal.destroyAll();
                    this.getList();
                }else{
                    message.error(`修改失败:${res?.message ||'-'}`)
                }
            }
        })
    }

    //获取短信记录信息
    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        value.pageNum = page || 1
        value.pageSize = PAGE_SIZE
        let res = await queryOrganization(value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum=page
            this.setState({tableData:res, pageNum:page})
        }
    }
    initColumn = ()=>{
        this.columns=[
            {
                title: '组织名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '备注',
                dataIndex: 'desc',
                key: 'desc',
            },
            {
                title: '操作',
                dataIndex: '-',
                key: 'id',
                render: (_, record)=>{
                    return (<div>
                        <Button type={'link'} onClick={()=>this.showDeleteConfirm(record)}>删除</Button>
                        <Button type={'link'} onClick={()=>{this.setState({updateOne: record}, ()=>this.setState({updateModalOpen:true}))}}>修改</Button>
                    </div>)
                }
            },
        ]
    }

}