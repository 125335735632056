/**
 *
 */
import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, Row} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import {PAGE_SIZE} from '../../utils/constants'
import {queryFaceAlarm} from '../../api/safoneIndex'
import FaceAlarmDetail from '../pageNew/faceAlarmDetail'

export default class  extends Component {
    state={
        pageState:'table_list',
        imgList:[],
        tableData:[],
        loading:true,
        addModalOpen: false,
        updateModalOpen: false,
        updateOne: {},
        deviceAreaMap: {},
        deviceAreaList: [],
        detailOne: {},
    }
    searchForm = React.createRef();

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {pageState,tableData,loading,detailOne} = this.state
        const  contentList={
            card_title:<PageTitle title="报警信息"  />,
            card_extra:pageState==='table_list'?<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="deviceId">
                                <Input style={{width: 150}} placeholder="设备号"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.setState({addModalOpen: true})}}>添加</Button>
                        </Col>
                    </Row>
                </Form>
            </div>:<Button onClick={()=>this.setState({pageState: 'table_list'})}>返回</Button>,
            table_list:<MyTable column={this.columns} data={tableData} loading={loading} pageFun={(c)=>{this.getList(c)}}/>,
            detail_page: <FaceAlarmDetail
                    detailInfo={detailOne}
            />
        }
        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
            </div>
        )
    }

    returnBtn = ()=>{
       this.setState({pageState:'table_list'})
    }
    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        value.pageNum = page || 1
        value.pageSize = PAGE_SIZE
        let res = await queryFaceAlarm(value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum=page
            this.setState({tableData:res, pageNum:page})
        }
    }
    initColumn = ()=>{
        this.columns=[
            {
                title: '设备序列号',
                dataIndex: 'deviceId',
                key: 'deviceId',
            },
            {
                title: '报警记录',
                dataIndex: 'alarmState',
                key: 'alarmState',
                render: (_, record)=>{
                    if('alarm_finished'===_){
                        return <span style={{color:'green'}}>已完成</span>
                    }else {
                        return <span style={{color:'orange'}}>处理中</span>
                    }
                }
            },
            {
                title: '人脸预警',
                dataIndex: 'isTarget',
                key: 'createTime',
                render: (_, record)=>{
                    if(_){
                        return <span style={{color:'red'}}>匹配</span>
                    }else {
                        return <span style={{color:'green'}}>未匹配</span>
                    }
                }
            },
            {
                title: '报警时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '操作',
                dataIndex: '-',
                key: 'id',
                render: (_, record)=>{
                    return (<div>
                        <Button type={'link'} onClick={()=>{this.setState({'pageState':'detail_page', 'detailOne':record})}}>详情</Button>
                    </div>)
                }
            },
        ]
    }

}