import React, {Component} from 'react';
import './index.css'
import {
    reqGetRecordTaskList,
    reqGetRecordDownloadStop,
    reqGetRecordDownloadStart,
    reqGetGBRecordList,
    reqPlayGBRecord,
    reqGetDeviceChannelList,
    reqSeekPlayingRecord,
    reqSpeedPlayingRecord,
    reqStopPlayingRecord, reqUpdateDeviceChannel, reqGetDeviceChannelUpdateStatus,
} from '../../api/index'
import {Button, message, Spin, Form, Select, DatePicker, Slider, Col, Row, Table, TimePicker, Modal} from "antd";
import 'antd/dist/antd.css'
import header1 from '../../assets/videoImages/header1.png'
import goBack from '../../assets/videoImages/goback.png'
import sessionUtils from "../../utils/sessionUtils";
import {SUCCESS} from "../../utils/constants";
import moment from "moment";
import { CloseSquareFilled } from '@ant-design/icons';
import FlvPlayer from "xgplayer-flv";


const {RangePicker} = DatePicker;
let downloadListTimer = "";
let player = null;
class matter extends Component{
    constructor(props) {
        super(props);
        this.state={
            list:[],
            loading:false,
            deviceInfo: {},
            isLive: false,
            channelList:[],
            recordList:[],
            tableLoading: false,
            channelId: '',
            recordInfo:{},
            showTimeText:'00:00:00',
            streamId:'',
            recordTaskTableLoading: false,
            recordTaskList:[],
            recordTaskPageNum: 1,
            recordTaskTotal:0,
            showDownloadListModal:false,
            showDownloadModal: false,
            downloadConfirmLoading: false,
            channelLoading: false,
        }
        this.searchFormRef = React.createRef();
        this.downloadFormRef = React.createRef();
        this.deviceChannelUpdateTimer = "";
    }

    componentWillUnmount() {
        if(this.deviceChannelUpdateTimer){
            clearInterval(this.deviceChannelUpdateTimer);
        }
        if(downloadListTimer){
            clearInterval(downloadListTimer);
        }
    }

    componentDidMount(){
        this.initColumn();
        let deviceInfo = sessionUtils.getDeviceInfo();
        if(!deviceInfo || !deviceInfo.deviceId){
            message.error("获取摄像头数据失败")
        }
        this.getDeviceChannelList(deviceInfo.deviceId);
        this.setState({
            deviceInfo: deviceInfo
        })
        player = new FlvPlayer({
            id: 'mse',
            isLive: true,
            playsinline: true,
            height: '405px',
            width: '720px',
            controls: false,
            url:'',
            errorTips: `请<span>刷新</span>试试`
        });
    }

    render() {
        const _that = this;
        const {channelLoading,recordTaskTotal,recordTaskList,recordTaskPageNum,recordTaskTableLoading,showDownloadListModal,downloadConfirmLoading,showDownloadModal,showTimeText, tableLoading, recordList, channelList, loading, deviceInfo, isLive} = this.state;
        const contentList = {
            "downloadModal":<Modal
                title="下载录像"
                visible={showDownloadModal}
                onOk={this.getRecordDownloadStart}
                onCancel={()=>{this.setState({showDownloadModal: false})}}
                okText="下载"
                cancelText="取消"
                confirmLoading={downloadConfirmLoading}
                destroyOnClose={true}
            >
                <Form ref={this.downloadFormRef}>
                    <Form.Item style={{marginBottom:10, marginLeft:10}} name="searchTime" rules={[{required:true, message:"请选择下载时间"}]}>
                        <RangePicker  placeholder={["开始时间","结束时间"]} showTime/>
                    </Form.Item>
                </Form>
            </Modal>,
            "downloadListModal":<Modal
                title="下载列表"
                visible={showDownloadListModal}
                width={1200}
                destroyOnClose={true}
                onCancel={()=>{
                    this.setState({showDownloadListModal: false});
                    if(downloadListTimer){
                        clearInterval(downloadListTimer);
                    }
                }}
                onOk={()=>{
                    this.setState({showDownloadListModal: false});
                    if(downloadListTimer){
                        clearInterval(downloadListTimer);
                    }
                }}
                footer={null}
            >
                <Table  columns={this.downloadTaskColumn}
                        bordered
                        rowKey={record => record.id}
                        dataSource={recordTaskList}
                        loading={loading}
                        rowClassName={(record, index) => {
                            let className = 'light-row';
                            if (index % 2 === 1) className = 'dark-row';
                            return className;
                        }}
                        pagination={{
                            total: recordTaskTotal,
                            showTotal: function () {  //设置显示一共几条数据
                                return '共 ' + recordTaskTotal + ' 条数据';
                            },
                            current:recordTaskPageNum,
                            pageSize: 10,
                            onChange(current) {
                                const {deviceInfo, channelId} = _that.state;
                                _that.getRecordTaskList({
                                    deviceId:deviceInfo.deviceId,
                                    channelId:channelId,
                                    pageNum:current,
                                });
                            },
                        }}
                />
            </Modal>
        };
        return (
            <div className={'video'} style={{width: "100%", height: "100%"}}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "rgb(8,9,102)",
                    padding: "5px",
                    alignItems: "center"
                }}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <img src={header1} alt="" style={{width: "70px", height: "50px"}}/>
                        <h2 style={{color: "white", fontWeight: "600"}}>录像回放</h2>
                    </div>
                    <div style={{marginRight: "20px", cursor: "pointer"}} onClick={this.goBack}>
                        <img src={goBack} alt="" style={{width: "40px", height: "40px"}}/>
                        <p style={{color: "white", textAlign: "center", marginBottom: "0", fontWeight: "600"}}>返回</p>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", paddingTop: "2%", height: "88%"}}>
                    <div style={{
                        width: "40%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}>
                        <div id='mse' />
                        <div style={{
                            width: "90%",
                            height: "90%",
                            position: "absolute",
                            display: loading ? "flex" : "none",
                            zIndex: "10000",
                            background: "black",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Spin/>
                        </div>
                    </div>

                    <div className={"MatterRight"} style={{width: "50%", height: "100%"}}>
                        <div style={{borderBottom: "1px solid white", paddingBottom: "20px", marginBottom: "20px"}}>
                            <h2 style={{color: "white"}}>基本信息</h2>
                            <div style={{display: "flex", color: "white", justifyContent: "space-between"}}>
                                <div><span>摄像头序列号：</span>{deviceInfo.deviceId}</div>
                                {/*<div><span>所属服务：</span>{deviceInfo.localName}</div>*/}
                                {/*<div><span>所属组织：</span>{deviceInfo.organizationName}</div>*/}
                            </div>
                        </div>
                        <div style={{display:"inline-block", verticalAlign:"top"}}>
                            <Form ref={this.searchFormRef}
                                  style={{margin:"0 15px ", display:"inline-block"}}
                                  name="advanced_search"
                                  className="ant-advanced-search-form"
                            >
                                <Col>
                                    <Row>
                                        <Form.Item  style={{marginBottom:10}} name="channelId" rules={[{required:true, message:"请选择通道"}]} >
                                            <Select placeholder="选择通道" style={{width:"300px"}}>
                                                {channelList.map(channel => <Select.Option value={channel.channelId} key={"channel" + channel.channelId}>
                                                    {channel.model} | {channel.channelId}
                                                </Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                        <div style={{marginLeft:"10px"}}>
                                            <Button loading={channelLoading} onClick={this.updateDeviceChannel}>更新通道</Button>
                                            <Button type="primary" style={{marginLeft:"10px"}} loading={isLive} onClick={this.getRecordList}>查询</Button>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Form.Item style={{marginBottom:10}} name="searchTime" rules={[{required:true, message:"请选择搜索时间"}]}>
                                            <RangePicker />
                                        </Form.Item>
                                        <div style={{marginLeft:"10px"}}>
                                            <Button type="primary" loading={isLive} onClick={this.showDownload}>下载录像</Button>
                                            <Button style={{marginLeft:"10px"}} loading={isLive} onClick={this.showDownloadTaskListModal}>下载列表</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Form>
                        </div>
                        <div style={{position: "relative",marginTop:"20px",backgroundColor:"white"}}>
                            <div style={{color:"black",padding:"10px"}}>录像控制
                                <Select style={{width:"150px", marginLeft:"10px"}} placeholder="倍速" onSelect={this.onSpeedChange}>
                                    <Select.Option value={0.25} key={0.25}>0.25倍速</Select.Option>
                                    <Select.Option value={0.5} key={0.5}>0.5倍速</Select.Option>
                                    <Select.Option value={1} key={1}>1倍速</Select.Option>
                                    <Select.Option value={2} key={2}>2倍速</Select.Option>
                                    <Select.Option value={4} key={4}>4倍速</Select.Option>
                                </Select>
                                <Button style={{marginLeft:"10px", verticalAlign:"bottom"}}
                                        title={"停止"}
                                        onClick={this.stopPlaying}
                                        icon={<CloseSquareFilled style={{width:"28px", fontSize:"28px", color:"grey"}} />}>
                                </Button>
                            </div>
                            <div style={{width:"100%", backgroundColor:"white", height:"50px",padding:"10px"}}>
                                <span>{showTimeText}</span>
                                <Slider
                                    style={{display:"inline-block", width:"70%", verticalAlign:"middle",marginLeft:"15px"}}
                                    defaultValue={0}
                                    max={100}
                                    range={false}
                                    onChange={this.playSeek}
                                />
                            </div>
                        </div>
                        <div style={{position: "relative",marginTop:"10px",backgroundColor:"white"}}>
                            <div style={{color:"black",padding:"10px"}}>录像列表</div>
                            <div style={{height:"100%", overflowY:"auto"}}>
                                <Table  columns={this.recordColumn}
                                        bordered
                                        rowKey={record => record.startTime}
                                        dataSource={recordList}
                                        loading={tableLoading}
                                        rowClassName={(record, index) => {
                                            let className = 'light-row';
                                            if (index % 2 === 1) className = 'dark-row';
                                            return className;
                                        }}
                                        pagination={false}
                                />
                            </div>
                        </div>
                        {contentList["downloadModal"]}
                        {contentList["downloadListModal"]}
                    </div>
                </div>
            </div>
        )
    }

    initColumn=()=>{
        this.recordColumn = [
            {
                title:"开始时间",
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title:"结束时间",
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title:"操作",
                dataIndex: '',
                key: 'delete',
                render:(e, record)=>{
                    return <div>
                        <Button type={'primary'} loading={this.state["loadingBtn" + record.startTime]} onClick={()=>{this.start(record)}}>播放</Button>
                    </div>
                }
            },
        ]
        this.downloadTaskColumn=[
            {
                title:"视频通道号",
                dataIndex: 'channelId',
                key: 'channelId',
            },
            {
                title:"录像开始时间",
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title:"录像结束时间",
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title:"任务创建时间",
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title:"下载进度",
                dataIndex: 'progress',
                key: 'progress',
                render:(e, record)=>{
                    if(record.progress){
                        return parseInt(record.progress * 100) + "%"
                    }
                    return "0%"
                }
            },
            {
                title:"状态",
                dataIndex: 'status',
                key: 'status',
                render:(e, record)=>{
                    let statusStr = "";
                    if(1 === e){
                        statusStr = <span>下载中...</span>;
                    }else if(2 === e){
                        statusStr = <span style={{color:"green"}}>已完成</span>;
                    }else if(3 === e){
                        statusStr = <span style={{color:"red"}}>已超时</span>;
                    }else if(4 === e){
                        statusStr = <span style={{color:"red"}}>已删除</span>;
                    }else if(5 === e){
                        statusStr = <span style={{color:"red"}}>已停止</span>;
                    }else if(6 === e){
                        statusStr = <span style={{color:"red"}}>下载失败</span>;
                    }
                    return statusStr;
                }
            },
            {
                title:"完成时间",
                dataIndex: 'completeTime',
                key: 'completeTime',
            },
            {
                title:"操作",
                dataIndex: '',
                key: 'delete',
                render:(e, record)=>{
                    let stopBtn = 1 === record.status?<Button loading={this.state["downloadStopLoadingBtn" + record.id]} onClick={()=>this.getRecordDownloadStop(record)}>停止</Button>:null;
                    let downloadBtn = 2===record.status && record.url ?<Button onClick={()=>{window.open(record.url)}}>下载</Button>:null;
                    return <div>
                        {stopBtn}
                        {downloadBtn}
                    </div>
                }
            },
        ]
    }

    getRecordList = async ()=>{
        const form = this.searchFormRef.current;
        const _that = this;
        if(form){
            form.validateFields().then(async (values)=>{
                const {deviceInfo} = _that.state;
                const searchTime = values.searchTime;
                if(searchTime && searchTime.length===2){
                    values.startTime = moment(searchTime[0]).format("YYYY-MM-DD 00:00:00")
                    values.endTime = moment(searchTime[1]).format("YYYY-MM-DD 23:59:59")
                    delete values.searchTime;
                }
                values.deviceId = deviceInfo.deviceId;
                _that.setState({tableLoading: true})
                const res = await reqGetGBRecordList(values);
                _that.setState({tableLoading: false})
                if(res && res.code && res.code === SUCCESS){
                    _that.setState({
                        recordList: res.results,
                        channelId: values.channelId,
                    })
                }
            });
        }
    }

    start = async (record)=>{
        const {deviceInfo, channelId} = this.state;
        const param = {
            deviceId: deviceInfo.deviceId,
            channelId: channelId,
            startTime: record.startTime,
            endTime: record.endTime,
        }
        const btnKey = {};
        btnKey["loadingBtn"+record.startTime] = true;
        this.setState(btnKey)
        const res = await reqPlayGBRecord(param);
        btnKey["loadingBtn"+record.startTime] = false;
        const showTimeText = record.startTime?record.startTime.substring(11):"00:00:00";
        this.setState({...btnKey, recordInfo: record, showTimeText:showTimeText});
        if(res && res.code && res.code === SUCCESS && res?.result?.code === 0){
            const streamInfoRes = res.result;
            if(streamInfoRes && streamInfoRes.data){
                const streamInfo = streamInfoRes.data
                this.setState({streamId: streamInfo.stream})
                if(player){
                    player.destroy(false);
                }
                player = new FlvPlayer({
                    id: 'mse',
                    isLive: true,
                    playsinline: true,
                    height: '405px',
                    width: '720px',
                    url: streamInfo?.flv,
                    autoplay:true,
                });
            }
        }else{
            message.error("回放失败")
        }
    }

    close = async ()=>{
    }

    goBack=()=>{
        window.history.back()
    }

    getDeviceChannelList=async (deviceId)=>{
        const res = await reqGetDeviceChannelList(deviceId);
        if(res && res.code && res.code === SUCCESS){
            this.setState({
                channelList: res.results
            })
        }
    }

    onSpeedChange=async (value)=>{
        const {streamId} = this.state;
        if(streamId){
            await reqSpeedPlayingRecord({
                streamId: streamId,
                speed: value
            });
        }
    }

    stopPlaying=async ()=>{
        const {deviceInfo, channelId, streamId} = this.state;
        if(player){
            if(player){
                player.destroy(false);
            }
            player = new FlvPlayer({
                id: 'mse',
                isLive: true,
                playsinline: true,
                height: '405px',
                width: '720px',
                controls: false,
                url:'',
            });
            this.setState({
                isLive:false,
                loading:false,
                videoUrl: null,
            })
            if(channelId && deviceInfo.deviceId){
                await reqStopPlayingRecord({
                    deviceId: deviceInfo.deviceId,
                    channelId: channelId,
                    streamId: streamId,
                });
            }
            this.setState({showTimeText:"00:00:00"})
        }
    }

    playSeek=async (val)=>{
        const {recordInfo, streamId} = this.state;
        if(recordInfo && streamId){
            const seekTime = new Date(recordInfo.endTime).getTime() - new Date(recordInfo.startTime).getTime();
            let showTime = new Date(new Date(recordInfo.startTime).getTime() + seekTime * val / 100)
            let hour = showTime.getHours();
            let minutes = showTime.getMinutes();
            let seconds = showTime.getSeconds();
            const showTimeText = (hour < 10?("0" + hour):hour) + ":" + (minutes<10?("0" + minutes):minutes) + ":" + (seconds<10?("0" + seconds):seconds)
            this.setState({
                showTimeText: showTimeText
            })
            const startTime = Math.floor(seekTime * val / 100000);
            await reqSeekPlayingRecord({
                streamId: streamId,
                startTime: startTime
            });
        }
    }

    getRecordTaskList=async (param)=>{
        param.pageNum = param.pageNum?param.pageNum:1;
        this.setState({recordTaskTableLoading: true});
        const res = await reqGetRecordTaskList(param);
        this.setState({recordTaskTableLoading: false});
        if(res && res.code && res.code === SUCCESS){
            this.setState({
                recordTaskList: res.results,
                recordTaskPageNum:param.pageNum,
                recordTaskTotal: res.totalCount,
            })
        }else{
            message.error("获取下载列表失败");
        }
    }

    getRecordDownloadStart=async ()=>{
        const form = this.downloadFormRef.current;
        if(form){
            const {deviceInfo, channelId} = this.state;
            form.validateFields().then(async (values)=>{
                values.startTime = moment(values.searchTime[0]).format("YYYY-MM-DD HH:mm:ss")
                values.endTime = moment(values.searchTime[1]).format("YYYY-MM-DD HH:mm:ss")
                values.deviceId = deviceInfo.deviceId;
                values.channelId = channelId;
                delete values.searchTime;
                this.setState({downloadConfirmLoading: true})
                const res = await reqGetRecordDownloadStart(values);
                this.setState({downloadConfirmLoading: false})
                if(res && res.code && res.code === SUCCESS){
                    message.success("请求成功，请前往'下载列表'查看下载进度并下载录像文件")
                    this.setState({
                        showDownloadModal: false,
                    })
                }else{
                    message.error("请求失败：" + res.message);
                }
            })
        }
    }

    getRecordDownloadStop=async (record)=>{
        const param = {id:record.id};
        const btnKey = {};
        btnKey["loadingBtn"+record.id] = true;
        this.setState(btnKey);
        const res = await reqGetRecordDownloadStop(param);
        btnKey["loadingBtn"+record.id] = false;
        this.setState(btnKey);
        if(res && res.code && res.code === SUCCESS){
            message.success("操作成功");
            const {deviceInfo, channelId, recordTaskPageNum} = this.state;
            this.getRecordTaskList({
                deviceId:deviceInfo.deviceId,
                channelId:channelId,
                pageNum: recordTaskPageNum,
            });
        }else{
            message.error("操作失败：" + res.message);
        }
    }

    showDownload=()=>{
        const form = this.searchFormRef.current;
        if(form){
            const _that = this;
            form.validateFields(["channelId"]).then((values)=>{
                const formValues = form.getFieldsValue();
                _that.setState({
                    showDownloadModal: true,
                    channelId: formValues.channelId
                })
            });
        }
    }

    showDownloadTaskListModal=async ()=>{
        const form = this.searchFormRef.current;
        const {deviceInfo} = this.state;
        if(form){
            const _that = this;
            form.validateFields(["channelId"]).then((values)=>{
                this.setState({
                    showDownloadListModal: true,
                    channelId:values.channelId
                }, ()=>{
                    _that.getRecordTaskList({
                        deviceId:deviceInfo.deviceId,
                        channelId:values.channelId,
                        pageNum:1,
                    });
                    if(downloadListTimer){
                        clearInterval(downloadListTimer);
                    }
                    downloadListTimer = setInterval(()=>{
                        _that.getRecordTaskList({
                            deviceId:deviceInfo.deviceId,
                            channelId:this.state.channelId,
                            pageNum: this.state.recordTaskPageNum,
                        });
                    }, 5000)
                })
            });
        }
    }

    updateDeviceChannel=async ()=>{
        const {deviceInfo} = this.state;
        this.setState({channelLoading: true});
        const res = await reqUpdateDeviceChannel({deviceId:deviceInfo.deviceId});
        if(res && res.code && res.code === SUCCESS){
            //开启轮询
            this.deviceChannelUpdateTimer = setInterval(() => this.getDeviceChannelUpdateStatus(), 1000);
        }else{
            this.setState({channelLoading: false})
            message.error("更新失败");
        }
    }

    getDeviceChannelUpdateStatus=async ()=>{
        const {deviceInfo} = this.state;
        const res = await reqGetDeviceChannelUpdateStatus({deviceId:deviceInfo.deviceId});
        if(res && res.code && res.code === SUCCESS){
            const {result} = res;
            if(result && result.current === 1){
                //轮询结束
                this.setState({channelLoading: false})
                if(this.deviceChannelUpdateTimer){
                    clearInterval(this.deviceChannelUpdateTimer);
                }
                message.success('更新成功，共获取'+ result.total +'条通道数据');
                this.getDeviceChannelList(deviceInfo.deviceId);
            }else if(result && result.errorMsg){
                message.error(result.errorMsg);
                if(this.deviceChannelUpdateTimer){
                    clearInterval(this.deviceChannelUpdateTimer);
                }
            }
        }else{
            this.setState({channelLoading: false});
            message.error("更新失败");
            if(this.deviceChannelUpdateTimer){
                clearInterval(this.deviceChannelUpdateTimer);
            }
        }
    }

}
export default matter;
