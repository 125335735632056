/**
 *
 */
import React, {Component} from 'react'
import {Card, Image, Table, Button} from 'antd'

export default class FaceAlarmDetail extends Component {
    constructor(props) {
        super(props);
        console.log(props.detailInfo)
        const {deviceAreaName='', alarmRecordList=[], } = props.detailInfo
        this.deviceAreaName = deviceAreaName
        this.state={
            pageState:'table_list',
            imgList:[],
            tableData:alarmRecordList,
            loading: false,
        }
    }


    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
    }

    componentDidMount(){
    }

    render() {
        const {tableData,loading, deviceAreaName} = this.state
        return(
            <Card className="card_main">
                <Table
                    size="small"
                    bordered
                    columns={this.columns}
                    rowKey={record => {
                        return record.faceUrl
                    } }
                    dataSource={tableData}
                    loading={loading?loading:false}
                    rowClassName={(record, index) => {
                        let className = 'light-row';
                        if (index % 2 === 1) className = 'dark-row';
                        return className;
                    }}
                    pagination={false}
                />
            </Card>)

    }
    initColumn = ()=>{
        this.columns=[
            {
                title: '所在区域',
                dataIndex: '-',
                key: '-',
                render: ()=>{
                    return this.deviceAreaName;
                }
            },
            {
                title: '匹配状态',
                dataIndex: 'matchState',
                key: 'name',
                render: (_, record)=>{
                    if('record_match_success'===_){
                        return <span style={{color:'red'}}>已匹配</span>
                    }else if('record_not_matched'===_){
                        return <span style={{color:'green'}}>未匹配</span>
                    }else {
                        return <span style={{color:'orange'}}>处理中</span>
                    }
                }
            },
            {
                title: '匹配结果',
                dataIndex: 'matchResult',
                key: 'matchResult',
                render: (_, record)=>{
                    if(record.faceId && record.faceDto){
                        return <Button type={'link'}>{record.faceDto?.name}</Button>
                    }else{
                        return '未匹配'
                    }
                }
            },
            {
                title: '匹配人脸',
                dataIndex: 'faceDto',
                key: 'faceDto',
                render: (_, record)=>{
                    if(record.faceId && record.faceDto && record.faceDto.faceImageList){
                        return (<Image src={record.faceDto?.faceImageList[0]} key={record.faceDto?.faceImageList[0]+'_face_img_url'} width={50} />)
                    }
                }
            },
            {
                title: '报警状态',
                dataIndex: 'alarmState',
                key: 'alarmState',
                render: (_, record)=>{
                    if('record_alarm_finished'===_){
                        return <span style={{color:'green'}}>报警结束</span>
                    }else if('record_alarm_success'===_){
                        return <span style={{color:'green'}}>报警成功</span>
                    }else if(null===_){
                        return ""
                    }else {
                        return <span style={{color:'orange'}}>报警失败</span>
                    }
                }
            },
            {
                title: '人脸图片',
                dataIndex: 'faceUrl',
                key: 'faceUrl',
                render: (_, record)=>{
                    return (<Image src={_} width={50} />)
                }
            },
            {
                title: '背景图片',
                dataIndex: 'backgroundUrl',
                key: 'backgroundUrl',
                render: (_, record)=>{
                    return (<Image src={_} width={50} />)
                }
            },
            {
                title: '采集时间',
                dataIndex: 'reportTime',
                key: 'reportTime',
            },
        ]
    }

}