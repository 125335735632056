import React, {Component} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row, Select, Table} from 'antd'
import jiahao from '../../assets/Img/加号.png'
import {SUCCESS} from '../../utils/constants'
import {
    reqRoleGetPermissionList,
    reqUserGetRoleList,
    reqUserGetUserList,
    reqUserInsert,
    reqUserReuseing,
    reqUserStop,
    reqUserUpdate,
    reqUserUpdatePassword
} from '../../api'
import {allOrganization,} from '../../api/safoneIndex'
import {removePropertyOfNull} from '../../utils/aladdin'

const {Option} = Select;
export default class User extends Component {
    constructor(props) {
        super(props);
        this.state={
            permissionOptions:[],
            roleOptions:[],
            users:[],
            loading:true,
            key:1,
            showPermission:false,
            showRecord:null,
            showUpdate:false,
            pageNum:1,
            updateOne:null,
            showAdd:false,
            showReset:false,
            organizationList:[],
        }
        this.searchFormRef = React.createRef();
        this.updateFormRef = React.createRef();
        this.addFormRef = React.createRef();
        this.resetFormRef = React.createRef();
    }
    componentWillMount(){
        this.initColumns();
    }

    componentDidMount(){
        this.getRoleList();
        this.getUserList({});
        this.getPermissionList({});
        this.reqGetAllOrganization();
    }

    render() {
        const _that =this;
        const {organizationList,showReset,showAdd,roleOptions,showUpdate,showPermission,showRecord,pageNum} = this.state;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 12 },
        };
        const contentList = {
            permissionModal: <Modal
                title="详情"
                visible={showPermission}
                onOk={()=>{this.setState({showPermission: false})}}
                onCancel={()=>{this.setState({showPermission: false})}}
            >
                <p>
                    {showRecord?showRecord:null}
                </p>
            </Modal>,
            updateModal:
                <Modal
                    title="修改帐号"
                    visible={showUpdate}
                    onOk={this.updateUser}
                    onCancel={() => {
                        this.setState({showUpdate: false,updateOne:null})
                    }}
                >
                    <Form ref={this.updateFormRef}>
                        <Form.Item label="帐&nbsp;&nbsp;&nbsp;&nbsp;号"  {...formItemLayout} name={'username'} rules={[{required:true,message:'帐号不能为空'}]}>
                            <Input type="text"
                                   disabled={true}
                                   style={{display:"block",marginBottom:"5px"}}/>
                        </Form.Item>
                        <Form.Item label="姓名" name={'name'} {...formItemLayout} rules={[{required:true,message:'姓名不能为空'}]}>
                            <Input type="text"
                                   style={{display:"block",marginBottom:"5px"}}
                            />
                        </Form.Item>
                        <Form.Item label="组织架构" name={'organizationId'} {...formItemLayout}>
                            <Select
                                showSearch
                                options={organizationList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                            />
                        </Form.Item>
                        <Form.Item label="角&nbsp;&nbsp;&nbsp;&nbsp;色" {...formItemLayout} name={'roleId'} rules={[{required:true,message:'请选择角色'}]}>
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                options={roleOptions}
                            />
                        </Form.Item>
                    </Form>
                </Modal>,
            addModal:<Modal
                title="添加账号"
                visible={showAdd}
                onOk={this.addUser}
                onCancel={()=>{this.setState({showAdd:false})}}
                key={this.state.key+1}
                destroyOnClose={true}
            >
                <Form ref={this.addFormRef}>
                    <Form.Item label="帐&nbsp;&nbsp;&nbsp;&nbsp;号" {...formItemLayout} name={'username'} rules={[{required:true,message:'帐号不能为空'}]}>
                        <Input type="text"
                               style={{display:"block",marginBottom:"5px"}}/>
                    </Form.Item>
                    <Form.Item label="姓名" name={'name'} {...formItemLayout} rules={[{required:true,message:'姓名不能为空'}]}>
                        <Input type="text"
                               style={{display:"block",marginBottom:"5px"}}
                        />
                    </Form.Item>
                    <Form.Item label="登陆密码" name={'password'} {...formItemLayout} rules={[{required:true,message:'密码不能为空'}]}>
                        <Input type="text"
                               style={{display:"block",marginBottom:"5px"}}
                        />
                    </Form.Item>
                    <Form.Item label="组织架构" name={'organizationId'} {...formItemLayout}>
                        <Select
                            showSearch
                            options={organizationList?.map((da)=>{return {label:da.name,value:da.id}}) || []}
                        />
                    </Form.Item>
                    <Form.Item label="角&nbsp;&nbsp;&nbsp;&nbsp;色" {...formItemLayout} name={'roleId'} rules={[{required:true,message:'请选择角色'}]}>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            options={roleOptions}
                        />
                    </Form.Item>
                </Form>
            </Modal>,
            resetPassword:<Modal
                title="重置密码"
                visible={showReset}
                onOk={this.resetPassword}
                onCancel={()=>{this.setState({showReset:false,updateOne:null})}}
                key={this.state.key+2}
            >
                <Form ref={this.resetFormRef}>
                    <Form.Item label="帐&nbsp;&nbsp;&nbsp;&nbsp;号" {...formItemLayout} name={'username'} rules={[{required:true,message:'帐号不能为空'}]}>
                        <Input type="text"
                               id="username"
                               disabled={true}
                               style={{display:"block",width:"80%",marginBottom:"5px"}}/>
                    </Form.Item>
                    <Form.Item label="姓名" name={'name'} {...formItemLayout} rules={[{required:true,message:'姓名不能为空'}]}>
                        <Input type="text"
                               disabled={true}
                               style={{display:"block",marginBottom:"5px"}}
                        />
                    </Form.Item>
                    <Form.Item label="新密码" name={'newPassword'} {...formItemLayout} rules={[{required:true,message:'新密码不能为空'}]}>
                        <Input type="text"
                               style={{display:"block",marginBottom:"5px"}}
                        />
                    </Form.Item>
                </Form>
            </Modal>,
            cardExtra:<div style={{margin:"10px 0",position:"relative"}}>
                <Button type="primary" onClick={this.showAdd} style={{margin:"0 10px", display:"inline-block"}}>
                    <img src={jiahao} alt="" style={{width:"20px",height:"20px",marginRight:"3px"}}/>添加账号
                </Button>
                <Form ref={this.searchFormRef}
                      size="small"
                      style={{margin:"0 15px ", display:"inline-block"}}
                      name="advanced_search"
                      className="ant-advanced-search-form"
                      onFinish={this.searchOnFinish} >
                    <Row gutter={24}>
                        <Col >
                            <Form.Item  style={{marginBottom:10}} name="name"  >
                                <Input style={{width:150}} placeholder="请输入用户名"/>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Form.Item  style={{marginBottom:10}} name="permission"  >
                                <Select style={{width:150}} placeholder="请选择权限">
                                    {this.state.permissionOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Button type="primary" htmlType="submit">搜索</Button>
                            <Button style={{ margin: '0 8px' }} onClick={this.handleResetClick}>重置</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
        };
        return (
            <div className="page_home">
                <Card className="card_main"
                      title="账号管理"
                      headStyle={{fontSize: 20, letterSpacing: 5, fontWeight: 600}}
                      extra={contentList['cardExtra']}>
                    <div>
                        <Table  columns={this.columns}
                                dataSource={this.state.users}
                                loading={this.state.loading}
                                rowKey={(record) => {
                                    return record.id;
                                }}
                                rowClassName={(record, index) => {
                                    let className = 'light-row';
                                    if (index % 2 === 1) className = 'dark-row';
                                    return className;
                                }}
                                pagination={{
                                    total: _that.state.userTotal,
                                    showTotal: function () {
                                        return '共 ' + _that.state.userTotal + ' 条数据';
                                    },
                                    pageSize: 10,
                                    current: pageNum,
                                    onChange:(current)=> {
                                        let param = _that.getSearchValues();
                                        param.pageNum = current;
                                        this.getUserList(param);
                                    },
                                }}
                        />
                    </div>
                </Card>
                <div>
                    {contentList['permissionModal']}
                    {contentList['updateModal']}
                    {contentList['addModal']}
                    {contentList['resetPassword']}
                </div>
            </div>
        )
    }
    updateUser=async ()=>{
        const form = this.updateFormRef.current;
        if(form){
            const _that = this;
            form.validateFields().then(async values => {
                values = removePropertyOfNull(values);
                const {updateOne} = _that.state;
                if(updateOne && updateOne.id){
                    values.id = updateOne.id;
                    if(values.roleId){
                        values.role = {id:values.roleId};
                    }
                    //移除账号组织
                    if(values.organizationId !== updateOne.organizationId && !values.organizationId){
                        values.organizationId = "";
                    }
                    const res = await reqUserUpdate(values);
                    _that.setState({
                        showUpdate:false,
                        updateOne:null,
                    })
                    if(res && res.code && res.code===SUCCESS){
                        message.success("修改成功");
                        _that.getUserList({});
                    }else{
                        message.error("修改失败");
                    }
                }
            });
        }
    }
    //打开修改用户弹窗
    showUserUpdate=async (e, record)=>{
        const _that = this;
        this.setState({
            showUpdate:true,
            updateOne: record
        }, ()=>{
            const form = _that.updateFormRef.current;
            if(form){
                form.setFieldsValue({username:record.username,name:record.name,organizationId:record.organizationId,roleId:record.role?record.role.id:null})
            }
        })
    }
    initColumns=()=>{
        this.columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 150,
                render:(text,record,index)=>{
                    return index+1;
                }
            },
            {
                title: '账号',
                dataIndex: 'username',
                key: 'username',
                width: 150
            },
            {
                title: '用户名',
                dataIndex: 'name',
                key: 'name',
                width: 150
            },
            {
                title: '角色名',
                dataIndex: 'roleDesc',
                key: 'roleDesc',
                render:(text, record)=>{
                    return (
                        <div>
                            {record.role.roleDesc}
                        </div>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text, record)=>{
                    let status = "";
                    if(record.status === 1){
                        status = <span>正常</span>;
                    }else if(record.status === 3){
                        status = <span style={{"color":"red"}}>已停用</span>;
                    }
                    return (
                        <div>
                            {status}
                        </div>
                    );
                }
            },
            {
                title: '权限',
                dataIndex: 'permissions',
                key: 'permissions',
                width: '300px',
                render:(text, record)=>{
                    const permissionStr = this.getPermissionModule(record.role.permissions);
                    if(permissionStr.length>50){
                        return (
                            <div>
                                {permissionStr.substring(0,50)+"..."}
                                <Button type={'link'} onClick={()=>{this.showPermissionDetails(permissionStr)}}>详情</Button>
                            </div>
                        );
                    }else{
                        return (
                            <div>
                                {permissionStr}
                            </div>
                        );
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text, record) => {
                    let status = "";
                    if(record.status === 1){
                        status = <Button type="primary" size={"small"} style={{"margin":"0px 5px 5px 0px"}} onClick={(e)=>{this.showStopUsing(e, record)}}>停用</Button>
                    }else if(record.status === 3){
                        status = <Button type="primary" size={"small"} style={{"margin":"0px 5px 5px 0px"}} onClick={(e)=>{this.showReusing(e, record)}}>启用</Button>
                    }
                    return (
                        <div>
                            <Button type="primary" size={"small"} style={{"margin":"0px 5px 5px 0px"}} onClick={(e)=>{this.showUserUpdate(e, record)}}>修改</Button>
                            {status}
                            <Button type="primary" size={"small"} style={{"margin":"0px 5px 5px 0px"}} onClick={(e)=>{this.showResetPassword(e, record)}}>重置密码</Button>
                        </div>
                    );
                }
            }
        ]
    }
    //获取组织
    reqGetAllOrganization = async ()=>{
        const res = await allOrganization()
        if(res?.code === SUCCESS){
            this.setState({
                organizationList: res?.results||[]
            })
        }else{
            message.error(`获取组织失败：${res?.message||''}`)
        }
    }

    //获取角色列表
    getRoleList=async ()=>{
        this.tableLoading(true);
        const res=await reqUserGetRoleList({});
        this.tableLoading(false);
        if(res.code===SUCCESS){
            let options = [];
            if(res.results){
                res.results.forEach(item => options.push({label:item.roleDesc,value:item.id}))
            }
            this.setState({
                roleOptions:options,
            });
        }
    }
    //获取权限列表
    getPermissionList= async (param)=>{
        let res = await reqRoleGetPermissionList(param);
        if(res.code === SUCCESS){
            if(res.results && res.results.length>0){
                this.getPermissionOptions(res.results);
            }
        }else{
            message.error("获取权限列表失败");
        }
    }

    //获取账号列表
    getUserList=async (param)=>{
        this.tableLoading(true);
        const res =await reqUserGetUserList(param);
        this.tableLoading(false);
        if(res.code===200){
            this.setState({
                users:res.results,
                userTotal:res.totalCount,
                pageNum:param.pageNum?param.pageNum:1
            });
        }
    }
    //渲染表格加载效果
    tableLoading=(flag)=>{
        this.setState({
            loading:flag
        })
    }

    //打开重置密码弹窗
    showResetPassword=(e, record)=>{
        const _that = this;
        this.setState({
            showReset:true,
            updateOne:record
        }, ()=>{
            const form = _that.resetFormRef.current;
            if(form){
                form.setFieldsValue(record);
            }
        })
    }
    //显示停用弹窗
    showStopUsing=(e, record)=>{
        const { confirm } = Modal;
        const _that = this;
        const showMsg = {user:record.name};
        setTimeout(() => {
            confirm({
                content: <span>确认停用 <em>{showMsg.organization}</em> 吗？</span>,
                async onOk () {
                    const res = await reqUserStop(record);
                    if(res.code === 200){
                        message.success("操作成功");
                        Modal.destroyAll();
                        _that.getUserList({});
                    }else{
                        message.error("操作失败, " + res.message);
                    }

                },
                onCancel() {
                    Modal.destroyAll();
                },
                maskClosable: true,
            });
        },  500);
    }
    //显示启用弹窗
    showReusing=(e, record)=>{
        const { confirm } = Modal;
        const _that = this;
        const showMsg = {user:record.name};
        setTimeout(() => {
            confirm({
                content: <span>确认启用 <em>{showMsg.organization}</em> 吗？</span>,
                async onOk() {
                    const res = await reqUserReuseing(record);
                    if(res.code === 200){
                        message.success("操作成功")
                        Modal.destroyAll();
                        _that.getUserList({});
                    }else{
                        message.error("操作失败, " + res.message);
                    }
                },
                onCancel() {
                    Modal.destroyAll();
                },
                maskClosable: true,
            });
        },  500);
    }
    //显示新增账号弹窗
    showAdd=()=>{
        this.setState({
            showAdd:true,
        })
    }
    //获取表格权限模块
    getPermissionModule=(permissions)=>{
        let map = permissions.map((permission)=>{
            return permission.permissionDesc;
        });
        return map.join("， ");
    }
    searchOnFinish=(values)=>{
        removePropertyOfNull(values);
        this.getUserList(values);
    }
    handleResetClick=()=>{
        this.searchFormRef.current.resetFields();
    }
    //获取搜索参数
    getSearchValues=()=>{
        const form = this.searchFormRef.current;
        let searchValues = form.getFieldsValue();
        removePropertyOfNull(searchValues);
        return searchValues;
    }
    //渲染权限单选框
    getPermissionOptions=(permissionList)=>{
        let options = [];
        if(permissionList && permissionList.length>0) {
            permissionList.map((e, i) => {
                options.push(<Option key={e.id} value={e.id}>{e.permissionDesc}</Option>);
            });
        }
        this.setState({
            permissionOptions: options
        })
    }
    showPermissionDetails=(permissions)=>{
        this.setState({
            showPermission: true,
            showRecord: permissions,
        })
    }
    addUser=async ()=>{
        const form = this.addFormRef.current;
        const _that = this;
        if(form){
            form.validateFields().then(async values => {
                _that.setState({
                    showAdd:false,
                })
                values = removePropertyOfNull(values);
                values.role = {id: values.roleId};
                const res = await reqUserInsert(values);
                if(res && res.code && res.code===SUCCESS){
                    message.success("添加成功");
                    _that.getUserList({});
                }else{
                    message.error("添加失败");
                }
            })
        }
    }
    resetPassword=async ()=>{
        const {updateOne} = this.state;
        const form = this.resetFormRef.current;
        this.setState({
            showReset: false,
            updateOne: null,
        }, async () => {
            if(updateOne && updateOne.id && form){
                let values = form.getFieldsValue();
                if(values.newPassword){
                    values.password = values.newPassword;
                    const res = await reqUserUpdatePassword(values);
                    if(res && res.code && res.code === SUCCESS){
                        message.success("重置成功");
                    }else{
                        message.error("操作失败");
                    }
                }
            }
        })

    }
}