/**
 *
 */
import React, {Component} from 'react'
import {Button, Card, Col, Form, Image, Input, message, Modal, Row, Upload} from 'antd'
import PageTitle from '../../common/pageTitle'
import MyTable from '../../common/myTable/myTable'
import {PAGE_SIZE, SUCCESS} from '../../utils/constants'
import {addFace, addFaceImage, queryFace, updateFace} from '../../api/safoneIndex'
import {UploadOutlined} from '@ant-design/icons'
import sessionUtils from "../../utils/sessionUtils";
import {URL_SAFONE} from '../../config/urlConf/urlConf'

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
export default class  extends Component {
    state={
        pageState:'table_list',
        imgList:[],
        tableData:[],
        loading:true,
        addModalOpen: false,
        updateModalOpen: false,
        uploadModalOpen: false,
        updateOne: {},
        uploadLoading: false,
        imageUrl: undefined,
        fileList: [],
    }
    searchForm = React.createRef();
    addForm = React.createRef();
    updateForm = React.createRef();

    componentWillMount(){
        this.initColumn()
    }
    componentWillUnmount(){
    }

    componentDidMount(){
        this.getList(1)
    }

    render() {
        const {fileList,uploadModalOpen,pageState,tableData,loading, addModalOpen, updateModalOpen, updateOne} = this.state
        const  contentList={
            card_title:<PageTitle title="人脸管理"  />,
            card_extra:<div style={{display:'flex'}}>
                <Form ref={this.searchForm}>
                    <Row gutter={15}>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="name">
                                <Input style={{width: 150}} placeholder="姓名"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{marginBottom: 10}} name="phoneNumber">
                                <Input style={{width: 150}} placeholder="手机号"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={()=>{this.getList(1)}}>搜索</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.searchForm.current.resetFields()}}>重置</Button>
                            <Button style={{margin: '0 8px'}} onClick={()=>{this.setState({addModalOpen: true})}}>添加</Button>
                        </Col>
                    </Row>
                </Form>
            </div>,
            add_modal: <Modal
                forceRender={true}
                title="添加人脸"
                visible={addModalOpen}
                confirmLoading={loading}
                destroyOnClose={true}
                width={500}
                onOk={async()=>{
                    this.reqAddFace()
                }}
                onCancel={()=>{this.setState({addModalOpen:false,})}}>
                <Form ref={this.addForm} style={{width:'100%'}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="姓名" name="name"  rules={[{required: true, message: '请输入姓名！'}]}>
                                <Input  placeholder="请输入姓名"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="电话号码" name="phoneNumber"  rules={[{required: true, message: '请输入电话号码！'}]}>
                                <Input  placeholder="请输入电话号码"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="备注" name="remark">
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            update_modal: <Modal
                forceRender={true}
                title="修改人脸"
                visible={updateModalOpen}
                confirmLoading={loading}
                width={500}
                destroyOnClose={true}
                onOk={async()=>{
                    this.reqUpdateFace()
                }}
                onCancel={()=>{this.setState({updateModalOpen:false,})}}>
                <Form ref={this.updateForm} style={{width:'100%'}} initialValues={{...updateOne}}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}  style={{marginBottom:10}} label="姓名" name="name"  rules={[{required: true, message: '请输入姓名！'}]}>
                                <Input  placeholder="请输入姓名"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} style={{marginBottom:10}} label="电话号码" name="phoneNumber"  rules={[{required: true, message: '请输入电话号码！'}]}>
                                <Input  placeholder="请输入电话号码"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item {...formItemLayout} style={{marginBottom:10}} label="备注" name="remark">
                                <Input  placeholder="请输入备注"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>,
            upload_face_modal: <Modal
                forceRender={true}
                title="上传人脸"
                visible={uploadModalOpen}
                confirmLoading={loading}
                width={500}
                destroyOnClose={true}
                onOk={this.reqAddFAceImage}
                onCancel={()=>{this.setState({uploadModalOpen:false,updateOne: {},fileList:[]})}}>
                <Upload
                    action={URL_SAFONE+"/api-platform/file/uploadFile"}
                    headers={{authorization: sessionUtils.getUser().token,}}
                    listType="picture"
                    onChange={(files)=>{
                        const {status, response} = files.file
                        if('done' === status){
                            const {code, result} = response
                            if(code === SUCCESS){
                                this.setState({fileList: [result]})
                            }else{
                                message.error('上传失败')
                            }
                        }
                    }}
                >
                    {fileList?.length>0?null:<Button type="primary" icon={<UploadOutlined />}>
                        上传
                    </Button>}
                </Upload>
            </Modal>,
            table_list:<MyTable column={this.columns} data={tableData} loading={loading} pageFun={(c)=>{this.getList(c)}}/>
        }
        return (
            <div className="page_home">
                <Card className="card_main" extra={contentList['card_extra']} title={contentList['card_title']}>
                    {contentList[pageState]}
                </Card>
                {contentList['add_modal']}
                {contentList['update_modal']}
                {contentList['upload_face_modal']}
            </div>
        )
    }

    reqAddFace = async ()=>{
        this.setState({loading: true})
        const _that = this
        this.addForm.current.validateFields().then(async (values)=>{
            const res = await addFace({...values});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({addModalOpen: false})
                _that.getList();
            }else{
                message.error(`添加失败:${res?.message ||'-'}`)
            }
        })
        this.setState({loading: false})
    }
    reqUpdateFace = async ()=>{
        this.setState({loading: true})
        const {updateOne} = this.state;
        const _that = this
        this.updateForm.current.validateFields().then(async (values)=>{
            const res = await updateFace({...values, id: updateOne.id});
            _that.setState({loading: false})
            if(res?.code === SUCCESS){
                _that.setState({updateModalOpen: false, updateOne: {}})
                _that.getList();
            }else{
                message.error(`修改失败:${res?.message ||'-'}`)
            }
        });
        this.setState({loading: false})
    }

    reqAddFAceImage = async ()=>{
        const {fileList} = this.state
        if(fileList && fileList.length>0){
            this.setState({loading: true})
            const {updateOne} = this.state;
            const res = await addFaceImage({fileUri:fileList[0], id: updateOne.id});
            this.setState({loading: false})
            if(res?.code === SUCCESS){
                this.setState({uploadModalOpen: false, updateOne: {},fileList:[]})
                this.getList();
            }else{
                message.error(`修改失败:${res?.message ||'-'}`)
            }
            this.setState({loading: false})
        }else{
            this.setState({uploadModalOpen: false, updateOne: {},fileList:[]})
        }
    }

    getList=async(page)=>{
        this.setState({loading:true})
        let value = this.searchForm.current.getFieldsValue();
        value.pageNum = page || 1
        value.pageSize = PAGE_SIZE
        let res = await queryFace(value?value:{})
        this.setState({loading:false})
        if(res&&res.code&&res.code===200){
            res.pageNum=page
            this.setState({tableData:res, pageNum:page})
        }
    }
    initColumn = ()=>{
        this.columns=[
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '手机号',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '人脸列表',
                dataIndex: 'faceImageList',
                key: 'faceImageList',
                render: (_, record)=>{
                    if(record.faceImageList){
                        return <div>
                            {record.faceImageList.map((imageUrl) => <Image key={imageUrl||new Date().getTime()+'_face_img'} width={50} src={imageUrl} />)}
                        </div>
                    }else {
                        return '-'
                    }
                }
            },
            {
                title: '操作',
                dataIndex: '-',
                key: 'id',
                render: (_, record)=>{
                    return (<div>
                        <Button type={'link'} onClick={()=>{this.setState({updateOne: record}, ()=>this.setState({updateModalOpen:true}))}}>修改</Button>
                        <Button type={'link'} onClick={()=>this.setState({updateOne: record}, ()=>this.setState({uploadModalOpen:true}))}>添加人脸</Button>
                    </div>)
                }
            },
        ]
    }

}